import React, { useEffect, useState } from 'react'
import Http from '../../Services/Http';
import { MdOutlineCurrencyRupee } from "react-icons/md";

function TotalMoney() {
    const [TotalMoney, SetTotalMoney] = useState(0)

  const fatchData = async () => {
    try {
      let res = await Http({
        url: `/order/totalMoney`,
        method: 'get',
        data: {}
      });
      if (res.data.success === true) {
        SetTotalMoney(res.data.totalMoney)
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    fatchData()
  }, [SetTotalMoney])
    return (
        <div>
            <div className='bg-white rounded-xl border border-[#cfd8dc] shadow-sm'>
                <div className='flex justify-between p-4'>
                    <MdOutlineCurrencyRupee size={45} className='bg-black rounded-xl p-3 text-white' />

                    <div>
                        <p className='text-[#7e7e7e] text-sm'>Total Money </p>
                        <h1 className='font-bold text-2xl text-right text-[#56ff08]'>{TotalMoney}</h1>
                    </div>
                </div>
                <div className='border-t border-[#cfd8dc] p-4'>
                    +55% <span className='text-[#7e7e7e]'>than last week</span>
                </div>
            </div>
        </div>
    )
}

export default TotalMoney