import React, { useState, useRef, useEffect } from 'react';
import Http from '../../Services/Http';

function Refer_Pay_Request() {
 
    // const [loading, setLoading] = useState(false);
    const loading = false
    const [seePastMessage, setSeePastMessage] = useState(false);
    const containerRef = useRef(null);
    const [paymentRequest, setPaymentRequest] = useState([]);
    const [token, setToken] = useState('');
    const [singlePayData, setsinglePayData] = useState(null);

    const getToken = () => {
        try {
            return localStorage.getItem('token') || '';
        } catch (error) {
            console.error("Error retrieving token from localStorage:", error);
            return '';
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setToken(getToken());
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await Http({
                    method: 'get',
                    url: `/referal/get_referal_pay_stms`,
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    data: {}
                });
                if (res.data.message) {
                    setPaymentRequest(res.data.user_ref_pay);
                }
            } catch (error) {
                console.log(error);
            }
        };
        if (token) {
            fetchData();
        }
    }, [token,setPaymentRequest]);

    const handleScroll = () => {
        // Handle scroll event
    };

    const handlePayReq = async (item) => {
        if (item?._id) {
            try {
                let res = await Http({
                    method: "get",
                    url: `/referal/get_referal_pay_stms_one/${(item?._id)}`,
                    headers: {
                        'Authorization': `Bearer ${token}` 
                    },
                    data: {}
                });
                if (res?.data?.message) {
                    setsinglePayData(res.data.user_ref_pay);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const updateStatus = async () => {
        // if (singlePayData?._id) {
        //     try {
        //         let res = await Http({
        //             method: "post",
        //             url: `/referal/update_referal_pay_status/${singlePayData._id}`,
        //             headers: {
        //                 'Authorization': `Bearer ${token}`
        //             },
        //             data: { status: true }
        //         });
        //         if (res.data.message) {
        //             setsinglePayData({ ...singlePayData, status: true });
        //         }
        //     } catch (error) {
        //         console.log(error);
        //     }
        // }
    };

    return (
        <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-64 bg-white h-screen overflow-hidden shadow-sm border border-[#cfd8dc] rounded-xl">
                <div
                    id="toast-success"
                    className="flex items-center w-full p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                    role="alert"
                >
                    <div className="inline-flex items-center px-4 py-2 justify-center flex-shrink-0 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                        Payments Request
                    </div>
                </div>
                <div
                    ref={containerRef}
                    className="h-full p-6 overflow-y-auto overflow-x-hidden supportonly"
                    onScroll={handleScroll}
                    style={{ position: 'relative' }}
                >
                    {paymentRequest.map((item, index) => (
                        <div
                            id="toast-success"
                            key={index}
                            className="flex items-center border w-full p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                            role="alert"
                        >
                            <div
                                className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 ${item?.status
                                    ? 'text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200'
                                    : 'text-yellow-400 bg-yellow-100 rounded-lg dark:bg-yellow-800 dark:text-yellow-200'
                                    }`}
                            >
                                <svg
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <span className="sr-only">Check icon</span>
                            </div>
                            <div className="ms-3 text-sm font-normal">{item?.userId?.mobile}</div>
                            <button
                                type="button"
                                onClick={() => {
                                    setSeePastMessage(true);
                                    handlePayReq(item);
                                }}
                                className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                data-dismiss-target="#toast-success"
                                aria-label="Close"
                            >
                                <span className="sr-only">Close</span>
                                <svg
                                    className="w-10 h-10"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M9.293 3.293a1 1 0 011.414 0l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414L13.586 10 9.293 5.707a1 1 0 010-1.414z"
                                    />
                                </svg>
                            </button>
                        </div>
                    ))}
                    {loading && (
                        <div className="flex justify-center items-center">
                            <div className="loader"></div>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex-1 p-6">
                {seePastMessage && singlePayData && (
                    <div className="bg-white shadow-md rounded-lg p-6 mb-4">
                        <h3 className="text-lg font-semibold mb-4">Payment Request Details</h3>
                        <p><strong>Name:</strong> {singlePayData.userId.Firstname} {singlePayData.userId.Lastname}</p>
                        <p><strong>Email:</strong> {singlePayData.userId.email}</p>
                        <p><strong>Mobile:</strong> {singlePayData.userId.mobile}</p>
                        <p><strong>Total Amount:</strong> ₹{singlePayData.totalamount}</p>
                        <p><strong>UPI ID:</strong> {singlePayData.upiId}</p>
                        <p><strong>Status:</strong> {singlePayData.status ? 'Approved' : 'Pending'}</p>
                        {!singlePayData.status && (
                            <button
                                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                                onClick={updateStatus}
                            >
                                Approve Payment
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Refer_Pay_Request;
