import axios from "axios";
// const baseUrl = 'http://localhost:4000/api';

const baseUrl = 'https://scantagy.online/api'

const assignToken = (options) => {
    let tokenObj = { token: sessionStorage.getItem('token') };
    options.headers ? Object.assign(options.headers, tokenObj) : Object.assign(options, { headers: tokenObj });
    return options;
}

const Http = (options, noToken = false, noBaseUrl=false) => {
    let ops = {
        url: (noBaseUrl ? '' : baseUrl) + options.url,
        method: options.method,
        data: options.data,
        headers: noToken ? options.headers : assignToken(options).headers,
        withCredentials: true 

    };
    if (options.method === 'get') {
        ops.params = options.data; 
    } else {
        ops.data = options.data;
    }
    return axios(ops);
}

export default Http;