import React, { useEffect, useState } from 'react'
import { RxCrossCircled } from "react-icons/rx";
import Http from '../../Services/Http';

function TotalStanderFail() {
    const [allStanderinactive, SetallStanderinactive] = useState(0)

    const fatchData = async () => {
        try {
            let res = await Http({
                url: `/standardProduct/totalstanderproductinactive`,
                method: 'get',
                data: {}
            });
            if (res.data.success === true) {
                SetallStanderinactive(res.data.allStanderinactive)
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fatchData()
    }, [SetallStanderinactive]);
    return (
        <div>
            <div className='bg-white rounded-xl border border-[#cfd8dc] shadow-sm'>
                <div className='flex justify-between p-4'>
                    <RxCrossCircled size={45} className='bg-black rounded-xl p-3 text-white' />

                    <div>
                        <p className='text-[#7e7e7e] text-sm'>Total Inactive Products</p>
                        <h1 className='font-bold text-2xl text-right'>{allStanderinactive}</h1>
                    </div>
                </div>
                <div className='border-t border-[#cfd8dc] p-4'>
                    +55% <span className='text-[#7e7e7e]'>than last week</span>
                </div>
            </div>
        </div>
    )
}

export default TotalStanderFail