import React, { useContext } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { HiHome } from "react-icons/hi2";
import { TiThSmallOutline } from "react-icons/ti";
import { FaTicketSimple } from "react-icons/fa6";
import { SiAuthy } from "react-icons/si";
import { CgLogOut } from "react-icons/cg";
import { ToastContainer, toast } from 'react-toastify';
import { MdOutlinePlaylistRemove } from "react-icons/md";
import { MyContext } from '../contexts/MyContext';

function Sidebar() {
    const navigate = useNavigate();
    const { mobileToggle, setMobileToggle } = useContext(MyContext);
    console.log("mobileToggale", mobileToggle);
    const logoutHandler = () => {
        localStorage.removeItem('stmsuser');
        localStorage.removeItem('token');
        toast.success("logout successful!", {
            autoClose: 2000,
        });
        setTimeout(() => {
            navigate("/");
        }, 2000);
    }
    const toggleHandle = () => {
        console.log("Toggle state sidebar");
        setMobileToggle(false);

    };
    return (
        <div className={`${mobileToggle ? " " : "hidden md:block"} bg-white md:block m-4 md:m-0 fixed z-30 w-64  md:relative  h-full max-h-screen px-4 py-6 border border-[#cfd8dc] rounded-xl overflow-hidden shadow-sm flex flex-col`}>
            <ToastContainer />
            <MdOutlinePlaylistRemove onClick={toggleHandle} size={30} className={`absolute  md:hidden right-2 top-2`} />
            <div className="flex-grow">
                <h2 className="text-xl font-semibold text-gray-800 mb-10 text-center"><Link to={'https://www.scantagy.com/'} target='_blank'>Scantagy.com</Link></h2>
                <ul className="space-y-2">
                    <li onClick={() => setMobileToggle(false)}>
                        <NavLink
                            to={'/home/dashboard'}
                            activeClassName="text-gray-600 active hover:text-blue-500"
                            className="flex gap-3 w-full py-3 px-4   font-medium hover:bg-[#373737] hover:text-white rounded-md"
                        >
                            <HiHome size={20} className='' />

                            Dashboard
                        </NavLink>
                    </li>
                    <li onClick={() => setMobileToggle(false)}>
                        <NavLink to={'/home/Whatsapp'}
                            activeClassName="text-gray-600 active hover:text-blue-500"
                            className="flex gap-3 w-full py-3 px-4  hover:bg-[#373737] hover:text-white rounded-md"
                        >
                            <SiAuthy size={20} />
                            Whatsapp
                        </NavLink>
                    </li>
                    <li onClick={() => setMobileToggle(false)}>
                        <NavLink to={'/home/auths'}
                            activeClassName="text-gray-600 active hover:text-blue-500"
                            className="flex gap-3 w-full py-3 px-4  hover:bg-[#373737] hover:text-white rounded-md"
                        >
                            <SiAuthy size={20} />
                            Auths
                        </NavLink>
                    </li>
                    <li onClick={() => setMobileToggle(false)}>
                        <NavLink to={'/home/tickets'}
                            activeClassName="text-gray-600 active hover:text-blue-500"
                            className="flex gap-3 w-full py-3 px-4  hover:bg-[#373737] hover:text-white rounded-md"
                        >
                            <FaTicketSimple size={20} />
                            Tickets
                        </NavLink>
                    </li>
                    <li onClick={() => setMobileToggle(false)}>
                        <NavLink to={'/home/total'}
                            activeClassName="text-gray-600 active hover:text-blue-500"
                            className="flex gap-3 w-full py-3 px-4  hover:bg-[#373737] hover:text-white rounded-md"
                        >
                            <TiThSmallOutline size={20} />
                            Totel
                        </NavLink>
                    </li>
                    <li onClick={() => setMobileToggle(false)}>
                        <NavLink to={'/home/Refer_Pay_Request'}
                            activeClassName="text-gray-600 active hover:text-blue-500"
                            className="flex gap-3 w-full py-3 px-4  hover:bg-[#373737] hover:text-white rounded-md"
                        >
                            <TiThSmallOutline size={20} />
                            Refer Pay Request
                        </NavLink>
                    </li>
                </ul>
                <ul className='space-y-2'>
                    <li onClick={() => setMobileToggle(false)}>
                        <h1 onClick={() => logoutHandler()}
                            activeClassName="text-gray-600 active hover:text-blue-500"
                            className="flex gap-3 w-full py-3 px-4 cursor-pointer hover:bg-[#373737] hover:text-white rounded-md"
                        >
                            <CgLogOut size={20} className='mt-1' />
                            Logout
                        </h1>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Sidebar;
