import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import Http from '../../Services/Http';
import { MyContext } from '../../contexts/MyContext';

function Sidebartickets() {
    const {setsupport_Id } = useContext(MyContext);
    const containerRef = useRef(null);
    const [userdata, setUserdata] = useState(null);
    const [allSupport, setAllSupport] = useState([]);
    const [token, setToken] = useState('');
    const limit = 10;
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    console.log(allSupport);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            try {
                const storedData = localStorage.getItem('stmsuser');
                const userdata = storedData !== null ? JSON.parse(storedData) : null;
                setUserdata(userdata);
            } catch (error) {
                console.error("Error parsing user data from localStorage:", error);
                setUserdata(null);
            }

            try {
                const storedToken = localStorage.getItem('token');
                setToken(storedToken || '');
            } catch (error) {
                console.error("Error retrieving token from localStorage:", error);
                setToken('');
            }
        }
    }, []);

    const fetchData = useCallback(async () => {
        if (!userdata) return;
        setLoading(true);
        try {
            let res = await Http({
                method: "post",
                url: "/Support/get_Agent_Support",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: { userId: userdata?._id, page: page, limit: limit }
            });
            if (res?.data?.message === "GetMessage_200") {
                const newSupport = Array.isArray(res.data.support) ? res.data.support : [];
                setAllSupport(prevSupport => [...prevSupport, ...newSupport]);
                if (newSupport.length < limit) {
                    setHasMore(false);
                }
            }
        } catch (error) {
            console.error("Error fetching support data:", error);
        } finally {
            setLoading(false);
        }
    }, [userdata, token, page, limit]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && !loading && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        }
    };

    useEffect(() => {
        if (page > 1) {
            fetchData();
        }
    }, [page, fetchData]);

    const setSeePastMessage = (value) => {
        // Set see past message logic here
    };

    const handlePreviousSupport = (item) => {
        setsupport_Id(item._id)
    };

    return (
        <div className="w-full md:w-96 bg-white h-screen overflow-hidden shadow-sm border border-[#cfd8dc] rounded-xl">
            <div
                id="toast-success"
                className="flex items-center w-full p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                role="alert"
            >
                <div className="inline-flex items-center px-4 py-2 justify-center flex-shrink-0 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                    Your All Support
                </div>
            </div>
            <div
                ref={containerRef}
                className="h-full p-6 overflow-y-auto overflow-x-hidden supportonly"
                onScroll={handleScroll}
                style={{ position: 'relative' }}
            >
                {allSupport.map((item, index) => (
                    <div
                        id="toast-success"
                        key={index}
                        className="flex items-center border w-full p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                        role="alert"
                    >
                        <div
                            className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 ${item.status
                                ? 'text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200'
                                : 'text-yellow-400 bg-yellow-100 rounded-lg dark:bg-yellow-800 dark:text-yellow-200'
                                }`}
                        >
                            <svg
                                className="w-5 h-5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                            >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="sr-only">Check icon</span>
                        </div>
                        <div className="ms-3 text-sm font-normal">{(item.Subject).slice(0,18)}</div>
                        <button
                            type="button"
                            onClick={() => {
                                setSeePastMessage(true);
                                // Add logic to close message box if it is open
                                handlePreviousSupport(item);
                            }}
                            className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                            data-dismiss-target="#toast-success"
                            aria-label="Close"
                        >
                            <span className="sr-only">Close</span>
                            <svg
                                className="w-10 h-10"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    fill="currentColor"
                                    d="M9.293 3.293a1 1 0 011.414 0l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414L13.586 10 9.293 5.707a1 1 0 010-1.414z"
                                />
                            </svg>
                        </button>
                    </div>
                ))}
                {loading && (
                    <div className="flex justify-center items-center">
                        <div className="loader"></div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Sidebartickets;
