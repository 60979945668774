import React, { createContext, useState } from 'react';

const MyContext = createContext();

const MyProvider = ({ children }) => {
  const [mobileToggle, setMobileToggle] = useState(false);
  const [support_Id, setsupport_Id] = useState('')

  return (
    <MyContext.Provider value={{ mobileToggle, setMobileToggle ,support_Id, setsupport_Id }}>
      {children}
    </MyContext.Provider>
  );
};

export { MyProvider, MyContext };
