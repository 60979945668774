import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function Protected(props) {
  const navigate = useNavigate();
  const { Component } = props;

  const userdata = localStorage.getItem('stmsuser');
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (!token && !userdata) {
      navigate('/')
    }
  }, [token, navigate,userdata])

  return (
    <div>
      {token &&  <Component />}
    </div>
  )
}

export default Protected;