import React, { useEffect, useState } from 'react'
import { PiSealCheckFill } from "react-icons/pi";
import Http from '../../Services/Http';

function TotalOrderSuss() {
  const [ordersucces, Setordersucces] = useState(0)

  const fatchData = async () => {
    try {
      let res = await Http({
        url: `/order/totalordersucces`,
        method: 'get',
        data: {}
      });
      if (res.data.success === true) {
        Setordersucces(res.data.allOrder)
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    fatchData()
  }, [Setordersucces]);
  return (
    <div>
      <div className='bg-white rounded-xl border border-[#cfd8dc] shadow-sm'>
        <div className='flex justify-between p-4'>
          <PiSealCheckFill size={45} className='bg-black rounded-xl p-3 text-white' />

          <div>
            <p className='text-[#7e7e7e] text-sm'>Total success order</p>
            <h1 className='font-bold text-2xl text-right text-[#008000]'>{ordersucces}</h1>
          </div>
        </div>
        <div className='border-t border-[#cfd8dc] p-4'>
          +55% <span className='text-[#7e7e7e]'>than last week</span>
        </div>
      </div>
    </div>
  )
}

export default TotalOrderSuss;