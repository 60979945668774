import React, { useEffect, useState } from 'react'
import { RxCrossCircled } from "react-icons/rx";
import Http from '../../Services/Http';

function TotalOrderFail() {
    const [orderfail, Setorderfail] = useState(0)

  const fatchData = async () => {
    try {
      let res = await Http({
        url: `/order/totalorderfail`,
        method: 'get',
        data: {}
      });
      if (res.data.success === true) {
        Setorderfail(res.data.allOrder)
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    fatchData()
  }, [Setorderfail])
    return (
        <div>
            <div className='bg-white rounded-xl border border-[#cfd8dc] shadow-sm'>
                <div className='flex justify-between p-4'>
                    <RxCrossCircled size={45} className='bg-black rounded-xl p-3 text-white' />

                    <div>
                        <p className='text-[#7e7e7e] text-sm'>Total failed orders</p>
                        <h1 className='font-bold text-2xl text-right text-[#CD1C18]'>{orderfail}</h1>
                    </div>
                </div>
                <div className='border-t border-[#cfd8dc] p-4'>
                    +55% <span className='text-[#7e7e7e]'>than last week</span>
                </div>
            </div>
        </div>
    )
}

export default TotalOrderFail