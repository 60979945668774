import React, { useContext } from 'react';
import { IoNotifications } from "react-icons/io5";
import { HiUserCircle } from "react-icons/hi";
import { useLocation } from 'react-router-dom';
import { MdOutlineMenuOpen } from "react-icons/md";
import { MyContext } from '../../contexts/MyContext';

function PathSearch() {
    const location = useLocation();
    const { mobileToggle, setMobileToggle } = useContext(MyContext);

    console.log("mobileToggle", mobileToggle);

    const pathname = location.pathname.slice(1);
    const capitalizedPath = pathname
        .split('/')
        .map(segment => segment.charAt(0).toUpperCase() + segment.slice(1))
        .join('/');

    const openMobileToggle = () => {
        console.log("Toggle state changed");
        setMobileToggle(!mobileToggle);
    };

    return (
        <div className='flex justify-between mb-4 bg-white items-center rounded-lg px-5 py-3 border border-[#cfd8dc] shadow-sm'>
            <div onClick={openMobileToggle} className='md:cursor-pointer  md:hidden'>
                <MdOutlineMenuOpen size={30} className='cursor-pointer  md:hidden'/>
            </div>

            <div>
                <h1 className=' text-[#7e7e7e] text-sm'>{capitalizedPath}</h1>
            </div>

            <div className=' hidden md:block'>
                <div className='flex gap-5'>
                    <div className="flex">
                        <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                            Search
                        </span>
                        <input
                            type="text"
                            id="website-admin"
                            className="rounded-none rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Email id or user id"
                        />
                    </div>
                    <div className='flex mt-1 gap-1'>
                        <HiUserCircle size={20} className='mt-1' />
                        <h1 className='mt-[4px] text-[#7e7e7e] text-sm'>Profile</h1>
                    </div>
                    <div className='mt-1'>
                        <IoNotifications size={20} className='mt-1' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PathSearch;
