import React, { useCallback, useContext, useEffect, useState } from 'react';
import Sidebartickets from './Sidebartickets';
import { MyContext } from '../../contexts/MyContext';
import Http from '../../Services/Http';

function Tickets() {
  const { support_Id } = useContext(MyContext);
  // const [userdata, setUserdata] = useState(null);
  // const setUserdata = null;
  const [support, setSupport] = useState({});
  const [token, setToken] = useState('');
  const [UpdateMessage, setUpdateMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Function to retrieve user data from localStorage
  // const getUserData = () => {
  //   try {
  //     const storedData = localStorage.getItem('stmsuser');
  //     return storedData ? JSON.parse(storedData) : null;
  //   } catch (error) {
  //     console.error("Error parsing user data from localStorage:", error);
  //     return null;
  //   }
  // };

  // Function to retrieve token from localStorage
  const getToken = () => {
    try {
      return localStorage.getItem('token') || '';
    } catch (error) {
      console.error("Error retrieving token from localStorage:", error);
      return '';
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // setUserdata(getUserData());
      setToken(getToken());
    }
  }, []);
  const fetchData = useCallback(async () => {
    try {
      const res = await Http({
        method: 'get',
        url: `/Support/getOneSupport/${support_Id}`,
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: {}
      });
      if (res.data.message === "GetOneMessage_200") {
        setSupport(res.data.support);
      }
    } catch (error) {
      console.error("Error fetching support data:", error);
    }
  }, [support_Id, token]);

  useEffect(() => {
    if (support_Id) {
      fetchData();
    }
  }, [support_Id, fetchData]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (UpdateMessage.trim() === '') {
      setErrorMessage('Message cannot be empty');
      return;
    }
    setErrorMessage('');
    if (UpdateMessage !== "" && token && support_Id) {
      try {
          let res = await Http({
              method: "patch",
              url: `/Support/UpdateSupport`,
              headers: {
                  'Authorization': `Bearer ${token}`
              },
              data: {_id:support_Id,AgentMessage:UpdateMessage}
          });
          if (res?.data?.message === "Support updated successfully") {
            // set_New_updated_Support(res.data.updatedSupport)
            fetchData();
          }
      } catch (error) {
          console.log(error);
          
      }
  }
    console.log(UpdateMessage);
  };

  return (
    <div className='w-full h-full md:flex'>
      <Sidebartickets />
      <div className="w-full md:p-6 mt-5 md:mt-0">
        <form className="bg-white shadow-md rounded md:px-8 md:pt-6 md:pb-8 mb-4" onSubmit={onSubmit}>

          <div className="mb-4">

            <div className="w-full mb-4 border border-gray-200 rounded-lg overflow-hidden bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
              <div className="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600">
                <div className='w-full h-full'>

                  <div className='bg-gray-100 px-2 py-2 rounded-md '>
                    <span className='font-medium '>Subject:</span>  {support?.Subject}
                  </div>
                  <div className='flex justify-between gap-2 md:gap-16 my-5'>
                    <div className='w-[50%]'>
                      {support?.AgentMessage && support.AgentMessage.map((item, index) => (
                        <div key={index} className='flex gap-2 w-full'>
                          <img
                            className="w-8 md:w-12 h-8 md:h-12 rounded-full  p-1 shadow-lg  border"
                            src="https://static.vecteezy.com/system/resources/previews/000/330/572/non_2x/support-agent-line-black-icon-vector.jpg"
                            alt="Jese"
                          />
                          <div className="flex w-full flex-col leading-1.5 p-4 mt-5 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700">
                            <p className="text-sm font-normal text-gray-900 dark:text-white">
                              <div >
                                {item}
                              </div>
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className='w-[50%]'>
                      {support?.SupportMessage && support.SupportMessage.map((item, index) => (
                        <div key={index} className='flex gap-2 w-full'>
                          <div className="flex w-full flex-col mt-5 leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700">
                            <p className="text-sm  font-normal text-gray-900 dark:text-white">
                              {item}
                            </p>
                          </div>

                          <img
                            className="w-8 md:w-12 h-8 md:h-12 rounded-full bg-[#f3f4f6]  shadow-lg  border"
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvts5aHBstDkR8PigS4RmZkbZy78zpZoSuOw&s"
                            alt="Jese"
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                </div>
              </div>
              <div className="flex items-center px-4 py-2 bg-white rounded-b-lg dark:bg-gray-800">
                <input
                  id="message"
                  name="message"
                  onChange={(e) => setUpdateMessage(e.target.value)}
                  className="block w-full px-0 py-3 text-sm text-gray-800 bg-white border-0 outline-none dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                  placeholder="Write an article..."
                  defaultValue={""}
                />
                <button
                  type="submit"
                  className="inline-flex items-center cursor-pointer px-5 py-2.5 text-sm font-medium text-center text-white bg-[#1d4ed8] rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800"
                >Submit</button>
              </div>
              {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}
            </div>

          </div>
        </form>
      </div>
    </div>
  );
}

export default Tickets;
