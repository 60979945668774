import React from 'react'
// import Chart from "react-apexcharts";

function Chart1() {
    // const [options, setOptions] = useState({
    //     chart: {
    //       id: "basic-bar",
    //       background: '#ffffff'
    //     },
    //     xaxis: {
    //       categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
    //     }
    //   });
    
    //   const [series, setSeries] = useState([
    //     {
    //       name: "series-1",
    //       data: [30, 40, 45, 50, 49, 60, 70, 91]
    //     }
    //   ]);
  return (
    <div className=' w-full  '>
          {/* <Chart
            options={options}
            series={series}
            type="bar"
            height="350"
            width="450"
            className="bg-white w- p-5 rounded-md border border-[#cfd8dc]"
          /> */}
    </div>
  )
}

export default Chart1