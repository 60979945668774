import React from 'react'
import Totaluser from '../Countcard/Totaluser'
import TotalOrderSuss from '../Countcard/TotalOrderSuss';
import TotalOrderFail from '../Countcard/TotalOrderFail';
import TotalStanderProduct from '../Countcard/TotalStanderProduct';
import TotalStanderSuccess from '../Countcard/TotalStanderSuccess';
import TotalStanderFail from '../Countcard/TotalStanderFail';
import TotalMoney from '../Countcard/TotalMoney';

function Totaldetails() {
    return (
        <>
            <div>
                <h1 className='text-2xl font-medium font-serif'>Total users and orders</h1>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 gap-5 my-5'>
                    <Totaluser />
                    <TotalMoney/>
                    <TotalOrderSuss />
                    <TotalOrderFail />
                </div>
            </div>
            <div>
                <h1 className='text-2xl font-medium font-serif'>Total Stander Products</h1>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 gap-5  my-5'>
                    <TotalStanderProduct />
                    <TotalStanderSuccess/>
                    <TotalStanderFail/>
                </div>
            </div>
            <div>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 gap-5 '>

                </div>
            </div>

        </>

    )
}

export default Totaldetails;