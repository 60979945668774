import React from 'react'
import StatisticsChart from '../Charts/Chart1'
function Dashboard() {
  return (
    <div className='grid grid-cols-2 gap-5'>
        <StatisticsChart/>
    </div>
  )
}

export default Dashboard