import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../Pages/Home'
import Dashboard from '../Pages/Dashboard/Dashboard'
import Totaldetails from '../Pages/Total/Totaldetails'
import Tickets from '../Pages/Tickets/Tickets'
import Login from '../Auth/Login'
import Register from '../Auth/Register'
import Protected from './Protected'
import Whatsapp from '../WhatsappAPI/Whatsapp/GereranlMesssage'
import ReferPayRequest from '../Pages/Refer_Pay_Request/Refer_Pay_Request'

function Routerm() {
    return (
        <>

            <Routes>
                <Route path='/' element={<Login />} />
                
                <Route path='/home' element={<Protected Component={Home} />}>
                    <Route path='dashboard' element={<Dashboard />} />  
                    <Route path='Whatsapp' element={<Whatsapp />} />
                    <Route path='total' element={<Totaldetails />} />
                    <Route path='tickets' element={<Tickets />} />
                    <Route path='auths' element={<Register />} />
                    <Route path='Refer_Pay_Request' element={<ReferPayRequest />} />
                </Route>

            </Routes>
        </>
    )
}

export default Routerm