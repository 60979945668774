import React, { useEffect, useState } from 'react'
import { FaUsersRays } from "react-icons/fa6";
import Http from '../../Services/Http';

function Totaluser() {
    const [userCount, SetuserCount] = useState(0)

    const fatchData = async () => {
        try {
           let res = await Http({
            url: `/auth/totaluser`,
                method:'get',
                data: {  }
           });
           if(res.data.success === true){
            SetuserCount(res.data.alluser)
           }
        } catch (error) {
            
        }
    }
    useEffect(() => {
        fatchData()
    }, [SetuserCount])
    
    return (
        <div>
            <div className='bg-white rounded-xl border border-[#cfd8dc] shadow-sm'>
                <div className='flex justify-between p-4'>
                    <FaUsersRays size={45} className='bg-black rounded-xl p-3 text-white'/>

                    <div>
                        <p className='text-[#7e7e7e] text-sm'>Total user</p>
                        <h1 className='font-bold text-2xl text-right'>{userCount}</h1>
                    </div>
                </div>
                <div className='border-t border-[#cfd8dc] p-4'>
                +55% <span className='text-[#7e7e7e]'>than last week</span>
                </div>  
            </div>
        </div>
    )
}

export default Totaluser