import React from 'react';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
import PathSearch from './PathSearch/PathSearch';

function Home() {


  return (
    <div className='w-screen h-screen flex overflow-hidden'>
      <div className='md:p-4 w-auto flex-shrink-0 h-full md:w-80'>
        <Sidebar />
      </div>
      <div className='flex-grow w-full overflow-x-hidden overflow-y-auto p-6 '>
        <PathSearch />
        <div className='text-gray-800'>
          <Outlet />

        </div>
      </div>
    </div>
  );
}

export default Home;
