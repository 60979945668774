import React from 'react'

function GereranlMesssage() {
  return (
    <div>
        
    </div>
  )
}

export default GereranlMesssage